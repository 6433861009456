
import Page from "@/core-ui/Page.vue";
import { defineComponent, onBeforeUnmount, ref } from "vue";

import frontendV2Service from "@/services/frontend-v2-service";
import { storageService } from "@/core-ui/data-grid/services";
import authStore, { LocalKeys } from "@/stores/authStore";
import clustersStore from "@/stores/clusters-store";
import appStore from "@/stores/appStore";
import { POST_MESSAGE_EVENTS } from "@/utils/postmessageEvents";
import { useRoute, useRouter } from "vue-router";
import Loading from "@/core-ui/Loading.vue";
import appV2Store, { getSidebarState } from "@/stores/appV2Store";

// cmps
import { supportBackPaths } from "@/router";

interface UpdateRoute {
    location: string;
    pageTitle: string;
    fullWidth: boolean;
    supportBack: boolean;
    closeIcon: boolean;
    readOnly: boolean;
}

export default defineComponent({
    components: {
        Page,
        Loading,
    },
    watch: {
        "$route.path"() {
            frontendV2Service.send(POST_MESSAGE_EVENTS.CURRENT_URL_CHANGED, { url: this.$route.fullPath });
        },
    },
    setup() {
        const route = useRoute();
        const router = useRouter();
        const loading = ref(true);
        const selectedCluster = ref(null as any);

        const baseUrl: string = frontendV2Service.url;
        const iframeEl = ref<HTMLElement>();

        function getAccessToken(): string {
            return storageService.getStr(LocalKeys.accessToken);
        }

        function getExternalToken(): string {
            return storageService.getStr(LocalKeys.externalToken);
        }

        const urlListenerId = frontendV2Service.addListener(POST_MESSAGE_EVENTS.CURRENT_URL_CHANGED, (data: any) => {
            router.push(data.url);
        });

        const urlBackListenerId = frontendV2Service.addListener(POST_MESSAGE_EVENTS.CURRENT_URL_BACK, () => {
            router.push(appStore.lastBackPath || "/");
        });

        const lastPageToBackListenerId = frontendV2Service.addListener(
            POST_MESSAGE_EVENTS.LAST_PAGE_TO_BACK,
            (path: string) => {
                const isPathSupportBack = supportBackPaths.some((currentPath) => path.includes(currentPath));
                if (!isPathSupportBack) {
                    appStore.lastBackPath = path;
                }
            },
        );

        const iframeLoadedListenerId = frontendV2Service.addListener(POST_MESSAGE_EVENTS.IFRAME_LOADED, () => {
            loading.value = false;
        });

        const tokenListenerId = frontendV2Service.addListener(POST_MESSAGE_EVENTS.ACCESS_TOKEN, () => {
            const accessToken: string = getAccessToken();
            const externalToken: string = getExternalToken();
            frontendV2Service.send(POST_MESSAGE_EVENTS.ACCESS_TOKEN, { accessToken, externalToken });
        });

        const initDataAppListenerId = frontendV2Service.addListener(POST_MESSAGE_EVENTS.USER_DATA, () => {
            frontendV2Service.send(POST_MESSAGE_EVENTS.USER_DATA, { lastLogin: appStore.lastLogin });
        });

        const logoutListenerId = frontendV2Service.addListener(POST_MESSAGE_EVENTS.DO_LOGOUT, () => {
            authStore.logout(true);
        });

        const clusterListListenerId = frontendV2Service.addListener(POST_MESSAGE_EVENTS.REFRESH_CLUSTERS_LIST, () => {
            clustersStore.fetchClusters();
        });

        const clusterListenerId = frontendV2Service.addListener(POST_MESSAGE_EVENTS.CURRENT_CLUSTER, () => {
            frontendV2Service.send(POST_MESSAGE_EVENTS.CURRENT_CLUSTER, { currentCluster: clustersStore.activeCluster });
        });

        const filtersListenerId = frontendV2Service.addListener(
            POST_MESSAGE_EVENTS.UPDATE_SEARCH_PARAMS,
            (data: any) => {
                const url = window.location.href.split("?")[0];
                window.history.replaceState(window.history.state, "", `${url}?${data.searchParams}`);
            },
        );

        const locationListenerId = frontendV2Service.addListener(
            POST_MESSAGE_EVENTS.UPDATE_LOCATION,
            (data: UpdateRoute) => {
                appV2Store.pageTitle = data.pageTitle;
                appV2Store.fullWidth = data.fullWidth;
                appV2Store.supportBack = data.supportBack;
                appV2Store.closeIcon = data.closeIcon;
                appV2Store.readOnly = data.readOnly;
                appV2Store.sidebarState = getSidebarState(data.fullWidth);
                const location: string = data.location;
                window.history.replaceState(window.history.state, "", `${window.location.origin}/${location}`);
            },
        );

        const sidenavStatusListenerId = frontendV2Service.addListener(POST_MESSAGE_EVENTS.INIT_APP, () => {
            frontendV2Service.send(POST_MESSAGE_EVENTS.INIT_APP, { sideBarState: appV2Store.sidebarState });
        });

        const moadalStatusListenerId = frontendV2Service.addListener(
            POST_MESSAGE_EVENTS.MODAL_MODE,
            (data: { isModalOpen: boolean }) => {
                data.isModalOpen
                    ? iframeEl.value?.classList.add("modal-open")
                    : iframeEl.value?.classList.remove("modal-open");
            },
        );

        onBeforeUnmount(() => {
            frontendV2Service.removeListener(POST_MESSAGE_EVENTS.IFRAME_LOADED, iframeLoadedListenerId);
            frontendV2Service.removeListener(POST_MESSAGE_EVENTS.CURRENT_URL_CHANGED, urlListenerId);
            frontendV2Service.removeListener(POST_MESSAGE_EVENTS.CURRENT_URL_BACK, urlBackListenerId);
            frontendV2Service.removeListener(POST_MESSAGE_EVENTS.CURRENT_URL_BACK, lastPageToBackListenerId);
            frontendV2Service.removeListener(POST_MESSAGE_EVENTS.ACCESS_TOKEN, tokenListenerId);
            frontendV2Service.removeListener(POST_MESSAGE_EVENTS.DO_LOGOUT, logoutListenerId);
            frontendV2Service.removeListener(POST_MESSAGE_EVENTS.CURRENT_CLUSTER, clusterListenerId);
            frontendV2Service.removeListener(POST_MESSAGE_EVENTS.USER_DATA, initDataAppListenerId);
            frontendV2Service.removeListener(POST_MESSAGE_EVENTS.INIT_APP, sidenavStatusListenerId);
            frontendV2Service.removeListener(POST_MESSAGE_EVENTS.MODAL_MODE, moadalStatusListenerId);
            frontendV2Service.removeListener(POST_MESSAGE_EVENTS.UPDATE_SEARCH_PARAMS, filtersListenerId);
            frontendV2Service.removeListener(POST_MESSAGE_EVENTS.UPDATE_LOCATION, locationListenerId);
            frontendV2Service.removeListener(POST_MESSAGE_EVENTS.REFRESH_CLUSTERS_LIST, clusterListListenerId);
            appV2Store.reset();
        });

        const searchParams = new URLSearchParams(window.location.search);

        if (route.query.clusterId && !clustersStore.isClusterExist(route.query.clusterId.toString())) {
            searchParams.delete("clusterId");
        }

        return {
            fullUrl: `${baseUrl}/#${route.path}?${searchParams.toString()}&parentOrigin=${window.location.origin}`,
            loading,
            iframeEl,
            selectedCluster,
        };
    },
});
